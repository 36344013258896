export default {
  // apiUrl: 'http://myapiurl.com',
  appName: "People and Projects",   // "VC Online",
  appSiteName: "IDMX",  // "Virtual Christianity",
  appCopyrightCompany: "Internet Dynamics, LLC",
  appTitle: "Get projects done with a great team of people on the go",
  appDescription: "Get projects done with a great team of people on the go. Works well for small to medium sized projects, especially for contractors, sub-contractors, and on-site service professionals.",
  appImage: "https://s3.amazonaws.com/creativetim_bucket/products/332/original/vue-material-dashboard-laravel-pro.jpg?1684310708",
  appKeywords: "people, projects, teams",
  appTwitterHandle: "@internetdynamics"
}